// React
// Helpers
import React from 'react';
import { wrapper } from '@store';
import { withRedux } from '@utils';
import { useTranslation } from 'react-i18next';
import dynamic from 'next/dynamic';
import { homeCarousel } from 'src/mocks';




// Third Party Modules
// Store

//* dynamic import
const CarouselSlider = dynamic(() => import('../src/components/slider/carousel-slider'));
const SavingFoodSection = dynamic(() => import('../src/features/home/saving-food-section'));
const WastedFoodSection = dynamic(() => import('../src/features/home/wasted-food-section'));
const RateInfoSection = dynamic(() => import('../src/features/home/rate-info-section'));
const DiscoverSection = dynamic(() => import('../src/features/home/discover-section'));
const NewsSection = dynamic(() => import('../src/features/home/news-section'));

// Home
const Home = () => {

  const { t } = useTranslation()
  {/* internalization example*/ }
  {/* <a>{t('Layout.header.item.post.index')}</a> */ }
  return (
    <>
      <CarouselSlider data={homeCarousel} />
      <SavingFoodSection />
      <WastedFoodSection />
      <RateInfoSection />
      <DiscoverSection />
      <NewsSection />
    </>
  );
};

export const getServerSideProps = wrapper.getServerSideProps((store) =>
  withRedux(
    async () => {
      return { props: {} };
    },
    { store },
  ),
);

// ServerSide Props
// export const getServerSideProps = wrapper.getServerSideProps((store) =>
//   withRedux(
//     async () => {
//       // Store Erişmek için SSR tarafında
//       const app = selectApp(store.getState());
//       // console.log({ app });
//       // Api
//       try {
//         // Get Data
//         const [comics1, comics2, comics3] = await Promise.all([
//           // await getUsername(),
//           (
//             await axios.get('https://www.breakingbadapi.com/api/')
//           ).data,
//           (await axios.get('https://www.breakingbadapi.com/api/')).data,
//           (await axios.get('https://www.breakingbadapi.com/api/')).data,
//         ]);
//         // Return
//         return { props: { comics1, comics2, comics3 } };
//       } catch (e) {
//         return { notFound: true };
//       }
//     },
//     { store },
//   ),
// );
// Export
export default Home;
